var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Imports",
      "sub-title": "View completed and in-progress imports and start new imports",
      "category": "Import Management",
      "category-logo": "fa-file-import",
      "category-route": {
        name: 'import-list'
      }
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": "fa-file-spreadsheet"
    }
  }, [_vm._v("Standard")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Import data using standard templates")]), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('router-link', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "to": {
        name: 'import-upload',
        query: {
          type: 'asset'
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-buildings mr-2"
  }), _vm._v("Assets")]), _c('router-link', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "to": {
        name: 'import-upload',
        query: {
          type: 'account'
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-meter-bolt mr-2"
  }), _vm._v("Accounts")]), _c('router-link', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "to": {
        name: 'import-upload',
        query: {
          type: 'entity'
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-sitemap mr-2"
  }), _vm._v(" Entities")]), _c('router-link', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "to": {
        name: 'import-upload',
        query: {
          type: 'invoice'
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-file-invoice mr-2"
  }), _vm._v("Invoices")]), _c('router-link', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "to": {
        name: 'import-upload',
        query: {
          type: 'contract'
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-file-contract mr-2"
  }), _vm._v("Contracts")]), _c('router-link', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "to": {
        name: 'import-upload',
        query: {
          type: 'reading'
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-input-numeric mr-2"
  }), _vm._v(" Readings")]), _c('router-link', {
    staticClass: "btn btn-primary mr-2",
    attrs: {
      "to": {
        name: 'import-upload',
        query: {
          type: 'consumption'
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-input-numeric mr-2"
  }), _vm._v(" Consumption (Non-Cumulative)")])], 1)], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": "fa-file-half-dashed"
    }
  }, [_vm._v("Other")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Import data using a non-standard or custom template")]), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, _vm._l(_vm.importTemplates.filter(function (it) {
    return it.name && it.schema;
  }), function (importTemplate) {
    return _c('router-link', {
      key: importTemplate._id,
      staticClass: "btn btn-primary mr-2",
      attrs: {
        "to": {
          name: 'import-upload',
          query: {
            type: 'importTemplate',
            importTemplateId: importTemplate._id,
            dataType: importTemplate.dataType
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-file mr-2"
    }), _vm._v(_vm._s(importTemplate.name))]);
  }), 1)], 1), _c('div', [_c('SectionTitle', {
    attrs: {
      "icon": "fa-clock-rotate-left"
    }
  }, [_vm._v("History")]), _vm.loading ? _c('div', [_c('Spinner')], 1) : _c('div', [_vm._m(0), _c('table', {
    staticClass: "table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.sortedImports, function (item) {
    var _item$importTemplate;
    return _c('tr', {
      key: item._id
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'import-detail',
          params: {
            id: item._id
          }
        }
      }
    }, [_vm._v(_vm._s(_vm._f("date")(item.createdAt, 'DD/MM/YYYY HH:mm:ss')))])], 1), _c('td', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(item.importTemplate ? item.importTemplate.dataType : item.type))]), _c('td', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s((_item$importTemplate = item.importTemplate) === null || _item$importTemplate === void 0 ? void 0 : _item$importTemplate.name))]), _c('td', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(item.status))]), _c('td', [_vm._v(_vm._s(item.notes))]), _c('td', [_vm._v(_vm._s(_vm._f("user")(item.userSub, _vm.users)))]), _c('td', [_c('router-link', {
      staticClass: "btn btn-primary btn-sm",
      attrs: {
        "to": {
          name: 'import-detail',
          params: {
            id: item._id
          }
        }
      }
    }, [_vm._v("Details")])], 1)]);
  }), 0)])])], 1)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Below is a list of all imports that have been processed. Click the "), _c('strong', [_vm._v("Details")]), _vm._v(" button to see more details about an import, and to also rollback an import. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "thead-dark"
  }, [_c('th', [_vm._v("Date")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Template")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Notes")]), _c('th', [_vm._v("Imported By")]), _c('th')]);

}]

export { render, staticRenderFns }